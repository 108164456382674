import React from "react";
import Logo from "../assets/logo.png";

const Footer = () => {
  return (
    <div className="bg-[#441e1e] rounded-t-3xl">
      <div className="w-11/12 mx-auto max-w-[1280px] pb-4">
        <div className="w-[100%] py-[1.6rem] flex flex-col justify-center items-center gap-1">
          <img
            src={Logo}
            alt=""
            className="w-[8rem] cursor-pointer"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />
          <p className="text-yellow-500 text-base">milletaahaar@gmail.com</p>

          <div className="flex justify-center items-center flex-wrap text-center mt-4 bg-green-800 p-1 px-2 rounded-full animate-bounce">
            <p className="text-sm text-white font-semibold">Developed & Maintained By Hariom Chouhan - </p>
            &nbsp;
            <a href="tel:9770994821" className="font-semibold underline text-white">
              +91 97709-94821
            </a>
          </div>
          {/* <span className="flex bg-green-800 text-white gap-2 w-36 justify-center items-center rounded-md p-1 px-2 md:px-1 lg:px-2">
            <ReactWhatsapp
              number="+91 7000106748"
              message=""
              className="text-lg flex gap-2 justify-center items-center md:text-sm lg:text-lg font-semibold"
            >
              Order Now
              <BsWhatsapp className="text-xl" />
            </ReactWhatsapp>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
